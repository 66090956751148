/*
 * Timeformatting
 */
import moment, {Moment} from 'moment-timezone';

let dateFormat = 'DD.MM.YYYY';
let dateFormatWithoutYears = 'DD.MM.';
let timeFormat = 'HH:mm';

function setFormats(american = true) {
	if (american) {
		dateFormat = 'MM/DD/YYYY';
		dateFormatWithoutYears = 'MM/DD';
		timeFormat = 'hh:mm A';
	} else {
		dateFormat = 'DD.MM.YYYY';
		dateFormatWithoutYears = 'DD.MM.';
		timeFormat = 'HH:mm';
	}
}

export function guessTimeFormat() {
	const isAmerican = moment('1990-12-28').format('L').startsWith('12');
	setFormats(isAmerican);
}

export function updateTimeFormat(eventId) {
	if (!localStorage.getItem('am_pm_time_format_' + eventId)) {
		setTimeFormat(eventId, false);
	}

	const isAmerican = JSON.parse(localStorage.getItem('am_pm_time_format_' + eventId));
	setFormats(isAmerican);
}

export function setTimeFormat(eventId, value: boolean) {
	localStorage.setItem('am_pm_time_format_' + eventId, value.toString());
	updateTimeFormat(eventId);
}

export function getTimeFormat(eventId) {
	return JSON.parse(localStorage.getItem('am_pm_time_format_' + eventId));
}

export function getCurrentDateFormat() {
	return dateFormat;
}

export function getCurrentTimeFormat() {
	return timeFormat;
}

export function getCurrentDateTimeFormat(years = true) {
	return (years ? dateFormat : dateFormatWithoutYears) + ' ' + timeFormat;
}

/*
 * Timezones
 */

const DEFAULT_TIMEZONE = 'UTC';
let timeZone = DEFAULT_TIMEZONE;

export function getTimeZone() {
	return timeZone;
}

export function setTimeZone(timeZoneToSet: string) {
	timeZone = timeZoneToSet;
}

export function resetTimeZone() {
	timeZone = DEFAULT_TIMEZONE;
}

export function dateIntervalFormatter(start: Moment, end: Moment) {
	if (!start.isSame(end, 'year')) {
		return start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY');
	} else if (!start.isSame(end, 'month')) {
		return start.format('D MMMM') + ' - ' + end.format('D MMMM, YYYY');
	} else if (!start.isSame(end, 'day')) {
		return start.format('D') + ' - ' + end.format('D MMMM, YYYY');
	} else {
		// single day
		return start.format('D MMMM, YYYY');
	}
}

/*
 * Local storage keys
 */
export const LOCALSTORAGE_CHANGELOG_VERSION = 'CHANGELOG_VERSION';
export const LOCALSTORAGE_AGENDA_ZOOM = (eventId) => 'AGENDA_ZOOM_' + eventId;
export const LOCALSTORAGE_AGENDA_TRIM_START = (eventId) => 'AGENDA_TRIM_START_' + eventId;
export const LOCALSTORAGE_AGENDA_DEFAULT_LENGTH = (eventId) => 'AGENDA_DEFAULT_LENGTH_' + eventId;
export const LOCALSTORAGE_DISQUALIFIED_USERS = 'DISQUALIFIED_USERS';
export const LOCALSTORAGE_QRCODE_COLOR = 'QRCODE_COLOR';
export const LOCALSTORAGE_USERMAVEN_DATA = 'USERMAVEN_DATA';
export const LOCALSTORAGE_GTM_CUSTOMER_STATUS = 'GTM_CUSTOMER_STATUS';