import {Injectable} from '@angular/core';
import {AuthStore} from './auth.store';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {
	createUser,
	createUserAuthInfo,
	createUserToServer,
	User,
	UserAuthInfo,
	UserAuthInfoFromServer,
	UserFromServer,
	UserSubscription
} from '../../core/models/user';
import {ID, resetStores, transaction} from '@datorama/akita';
import {SocialService} from '../../libraries/social/social.service';
import {cleanObject} from '../../shared/utils/clean-object';
import {TranslateService} from '@ngx-translate/core';
import {SmartTechnologiesService} from '../../libraries/smart-technologies/smart-technologies.service';
import {CompaniesService} from '../companies/companies.service';
import Bugsnag from '@bugsnag/js';
import Cookies from 'js-cookie';
import {Event} from '../../core/models/event';
import {FeatureFlagsService} from '../../libraries/feature-flags/feature-flags.service';
import {Company} from "../../core/models/company";
import {UsermavenService} from "../../shared/services/usermaven.service";
import {AuthQuery} from "./auth.query";
import {GoogleTagService} from "../../libraries/google-tag/google-tag.service";

enum SubscribeLanguage {
	ENGLISH = 'English',
	CZECH = 'Czech'
}

export interface ResetPasswordRequest {
	email: string;
	token: string;
	password: string;
	password_confirmation: string;
}

export interface ResetPasswordResponse {
	email: string;
	as_admin: boolean;
	token: string;
}

export interface RegisterWithTokenRequest {
	token: string;
	first_name: string;
	last_name: string;
	password: string;
	password_confirmation: string;
	to_company: boolean | ID;
}

export interface RegisterCredentials {
	email: string;
	password: string,
	subscribed: boolean,
	first_name: string,
	last_name: string,
}

export interface LoginCredentials {
	email: string;
	password: string;
}

export interface InviteValidationResponse {
	token?: string;
	company_id?: number;
	event_id?: number;
	event?: Event;
}

@Injectable({providedIn: 'root'})
export class AuthService {

	private suffixV1 = '/v1';
	private suffixV2 = '/v2';

	constructor(
		private authStore: AuthStore,
		private authQuery: AuthQuery,
		private translate: TranslateService,
		private socialService: SocialService,
		private companiesService: CompaniesService,
		private smartTechnologiesService: SmartTechnologiesService,
		private http: HttpClient,
		private featureFlagsService: FeatureFlagsService,
		private usermavenService: UsermavenService,
		private googleTagService: GoogleTagService) {
	}

	refresh(): Observable<string> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/token`).pipe(
			map((response: any) => response.token),
			tap(token => {
				this.authStore.setError(null);
				this.setToken(token);
			}),
			catchError((error: HttpErrorResponse) => {
				this.authStore.setLoading(false);
				this.removeToken();
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	login(credentials): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login`, credentials).pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.authStore.setError(null);
				this.setToken(userAuthInfo.token);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.authStore.setError(error.error.password);
					return of();
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	loginAs(email: string): Observable<string> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/login/as`, {
			email
		}).pipe(
			map((response: any) => {
				const token = response.token;
				this.authStore.setError(null);
				this.setToken(token);
				return token;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.authStore.setError(error.error.password);
					return of();
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	facebookLogin(): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.socialService.facebook().pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.authStore.setError(null);
				this.setToken(userAuthInfo.token);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	linkedInLogin(): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.socialService.linkedIn().pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.authStore.setError(null);
				this.setToken(userAuthInfo.token);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	appleLogin(): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.socialService.apple().pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.authStore.setError(null);
				this.setToken(userAuthInfo.token);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	@transaction()
	logout(): Observable<HttpResponse<object>> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		resetStores();

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/logout`, null, {observe: 'response'}).pipe(
			tap((response: any) => {
				this.authStore.setError(null);
			}),
			tap(() => this.featureFlagsService.setGrowthBookAttributes({
				userEmail: null,
				country: null,
				companyId: null,
				eventId: null
			})),
			finalize(() => {
				this.removeToken();
				this.authStore.setLoading(false);
			})
		);
	}

	me(): Observable<User> {
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/me`).pipe(
			map((user: UserFromServer) => {
				const u = createUser(user);
				this.companiesService.updateUserDefault(u.defaultCompanyId);
				return u;
			}),
			tap(user => this.authStore.update({user})),
            tap(user => this.updateUserForExternalServices(user)),
			catchError((error: HttpErrorResponse) => {
				console.error(error);

				if (error.status !== 401) {
					this.removeToken();
					return of(null);
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	updateProfile(user: User): Observable<User> {
		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/me`, cleanObject(createUserToServer(user))).pipe(
			map((response: UserFromServer) => {
				const u = createUser(response);
				this.authStore.updateUserProfile(u);
				this.updateUserForExternalServices(u);
				return u;
			}),
			catchError((error: HttpErrorResponse) => {
				console.error(error);

				if (error.status !== 401) {
					this.removeToken();
					return of(null);
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	register(credentials): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/register`, {
			...cleanObject(credentials)
		}).pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.authStore.setError(null);
				this.setToken(userAuthInfo.token);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 422) {
					this.authStore.setError('AUTH.REGISTER.ALREADY_EXISTS');
					return of();
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	afterLogin(): Observable<HttpResponse<object>> {
		const language = this.getUserSubscribeLanguage();
		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/login/after`, {
			language,
			ev_uid: Cookies.get('EV_UID')
		}, {observe: 'response'});
	}

	afterRegistration(subscribed: boolean): Observable<HttpResponse<object>> {
		const language = this.getUserSubscribeLanguage();
		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/login/after`, {
			subscribed,
			language,
			entry_params: Cookies.get('ENTRY_PARAMS') ?? location.search,
			ev_uid: Cookies.get('EV_UID'),
			cj_event: Cookies.get('CJ_EVENT'),
			cj_pid: Cookies.get('CJ_PID'),
			cj_aid: Cookies.get('CJ_AID'),
			cj_sid: Cookies.get('CJ_SID')
		}, {observe: 'response'});
	}

    createDefaultCompany(user: User) {
        let key = 'ADMIN.COMPANY.NAME.DEFAULT';
        let params = {};
        if (!!user.firstName) {
            key = 'ADMIN.COMPANY.NAME.USERS';
            params['name'] = user.firstName;
        }
        return this.translate.get(key, params).pipe(
            switchMap(companyName => this.companiesService.createCompany({
                name: companyName
            } as Company))
        );
    }

	forgotPassword(email: string): Observable<HttpResponse<object>> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.put(`${environment.appApi.baseUrl}${this.suffixV1}/reset/send`, {
			email,
			as_admin: true
		}, {observe: 'response'}).pipe(
			tap(() => {
				this.authStore.setError(null);
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	validateResetPassword(token: string): Observable<ResetPasswordResponse> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/reset/validate?token=${token}`).pipe(
			tap((response: ResetPasswordResponse) => {
				this.authStore.setError(null);
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	resetPassword(request: ResetPasswordRequest): Observable<string> {
		return this.http.patch(`${environment.appApi.baseUrl}${this.suffixV1}/reset`, {
			...cleanObject(request)
		}).pipe(
			map((response: { token: string }) => {
				this.authStore.setError(null);
				return response.token;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	validateInvitation(token: string, isCompanyValidation: boolean = false): Observable<InviteValidationResponse> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		let type = '';

		if (isCompanyValidation) {
			type = '/company';
		}

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/invitation${type}/validate?token=${token}`).pipe(
			tap(() => {
				this.authStore.setError(null);
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	changeEmail(email: string): Observable<User> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/me/change/email`, {
			email
		}).pipe(
			map((response: UserFromServer) => {
				const user = createUser(response);
				this.authStore.setError(null);
				this.authStore.updateUserProfile(user);
				return user;
			}),
            tap(newUser => this.updateUserForExternalServices(newUser)),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	changePassword(oldPassword: string, newPassword: string): Observable<User> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/me/change/password`, {
			old_password: oldPassword,
			new_password: newPassword
		}).pipe(
			map((response: UserFromServer) => {
				const user = createUser(response);
				this.authStore.setError(null);
				this.authStore.updateUserProfile(user);
				return user;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	getSubscriptionInfo(): Observable<UserSubscription> {
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV2}/me/subscriptions`).pipe(
			map(response => {
				return response as UserSubscription;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	updateSubscriptionInfo(newsletter: boolean, tutorial: boolean): Observable<UserSubscription> {
		const language = this.getUserSubscribeLanguage();
		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/me/subscriptions`, {
			language,
			newsletter,
			tutorial
		}).pipe(
			map(response => {
				return response as UserSubscription;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	registerWithToken(request: RegisterWithTokenRequest): Observable<User> {
		return this.http.patch(`${environment.appApi.baseUrl}${this.suffixV1}/invitation/register`, {
			...cleanObject(request)
		}).pipe(
			switchMap((response: {token: string}) => {
				this.setToken(response.token)
				this.authStore.setError(null);
				return this.authQuery.user$;
			}),
			map(user => {
				return user;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		)
	}

	setToken(token: string) {
		if (token === null) {
			this.removeToken();
			return;
		}
		localStorage.setItem('token', token);
		this.authStore.update(({
			token
		}));
	}

	removeToken() {
		localStorage.removeItem('token');
		this.authStore.update({
			token: null
		});
	}

	updateProfilePath(path: string) {
		this.authStore.updateProfilePath(path);
	}

	deleteAccount(email: string): Observable<HttpResponse<object>> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.delete(`${environment.appApi.baseUrl}${this.suffixV1}/me`, {
			params: {
				email: encodeURIComponent(email)
			},
			observe: 'response'
		}).pipe(
			tap(() => {
				this.removeToken();
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	private getUserSubscribeLanguage() {
		switch (this.translate.currentLang) {
			case 'cs':
			case 'sk':
				return SubscribeLanguage.CZECH;
			default:
				return SubscribeLanguage.ENGLISH;
		}
	}

	private updateUserForExternalServices(user: User){
	    this.smartTechnologiesService.saveEmail(user.id, user.email, user.firstName + ' ' + user.lastName, user.appsumo);
	    Bugsnag.setUser(user.id.toString(), user.email, user.name);
	    this.usermavenService.fullUserIdentification(user);
		this.googleTagService.identifyUser({
			userId: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			subscribed: user.subscribed
		})
	}
}
