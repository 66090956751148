import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {
    Company,
    CompanyFromServer,
    CompanyPackageRestrictions,
    CompanyPackageRestrictionsFromServer,
    createCompany,
    createCompanyPackageRestrictions,
    createCompanyPackageRestrictionsToServer,
    createCompanyToServer
} from '../../core/models/company';
import {CompaniesStore} from './companies.store';
import {ID} from '@datorama/akita';
import {createUser, getUserRoleType, User, UserFromServer} from '../../core/models/user';
import {cleanObject} from '../../shared/utils/clean-object';
import {createEvent, Event, EventFromServer, EventStatus} from '../../core/models/event';
import {EventsStore} from '../events/events.store';
import * as _ from 'lodash';
import {createInvoicePreview, InvoicePreview, InvoicePreviewFromServer} from '../../core/models/invoice-preview';
import {UsermavenService} from "../../shared/services/usermaven.service";
import {ProductFruitsService} from "../../shared/services/product-fruits.service";


@Injectable({
    providedIn: 'root'
})
export class CompaniesService {

    private suffixV1 = '/v1';
    private suffixV2 = '/v2';
    private suffixCompany = '/company';

    constructor(
        private companiesStore: CompaniesStore,
        private eventsStore: EventsStore,
        private http: HttpClient,
        private usermavenService: UsermavenService,
        private productFruitsService: ProductFruitsService) {
    }

    get(companyId: ID): Observable<Company> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixCompany}/${companyId}`).pipe(
            map((response: CompanyFromServer) => {
                const company = createCompany(response);
                this.companiesStore.setError(null);
                this.companiesStore.upsertMany([company]);
                return company;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    getAll(search?: string): Observable<Company[]> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        let params = new HttpParams();

        if (search?.length > 0) {
            params = params.set('search', search);
        }

        return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/me/companies`, {params}).pipe(
            map((response: CompanyFromServer[]) => {
                const result = response.map(e => createCompany(e));
                this.companiesStore.setError(null);
                this.companiesStore.upsertMany(result);
                return result;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    getPreview(page?: number): Observable<Company[]> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        const filter = this.eventsStore.getValue().ui.searchFilter;
        const query = filter.query;
        const status = filter.status;
        const limit = 10;
        const params = this.getFilterPaginationHttpParams(query, status, page, limit);

        return this.http.get(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixCompany}/preview`, {params}).pipe(
            map((response: CompanyFromServer[]) => {
                return response.map(company => createCompany(company))
            }),
            tap((response: Company[]) => {
                if (page === 0) {
                    this.companiesStore.set([]);
                    this.eventsStore.set([]);
                }
                const events = _.flatMap(response.map(c => c.userEvents));
                this.eventsStore.upsertMany(events);

                this.companiesStore.setError(null);
                this.companiesStore.upsertMany(response);
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    getDetail(companyId?: number, page?: number, limit: number = 32): Observable<Event[]> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        const filter = this.eventsStore.getValue().ui.searchFilter;
        const query = filter.query;
        const status = filter.status;
        const params = this.getFilterPaginationHttpParams(query, status, page, limit);

        let url = `${environment.appApi.baseUrl}${this.suffixV2}${this.suffixCompany}/detail`;
        if (!!companyId) {
            url = `${environment.appApi.baseUrl}${this.suffixV2}${this.suffixCompany}/${companyId}/detail`;
        }
        return this.http.get(url, {params}).pipe(
            map((response: EventFromServer[]) => {
                if (page === 0) {
                    this.eventsStore.set([]);
                }
                const events = response.map(e => createEvent(e));
                this.eventsStore.upsertMany(events);

                this.companiesStore.setError(null);
                return events;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    getFilterPaginationHttpParams(query?: string, status?: EventStatus, page?: number, limit?: number) {
        let params = new HttpParams().set('limit', limit + '');

        if (query?.length > 0) {
            params = params.set('search', query);
        }
        if (status && status > -1) {
            params = params.set('status', status.toString());
        }
        if (page && page > -1) {
            params = params.set('offset', (limit * page).toString());
        }
        return params;
    }

    getUsers(companyId: ID): Observable<User[]> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        const params = new HttpParams().set('pending', '1').set('role', 'a');

        return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixCompany}/${companyId}/users`, {params}).pipe(
            map((response: UserFromServer[]) => {
                this.companiesStore.setError(null);
                return response.map((u) => {
                    const user = createUser(u);
                    user.roleType = getUserRoleType(user.role, u.pending, false);
                    return user;
                }).sort((u1, u2) => u1.roleType - u2.roleType);
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    setActive(id: ID) {
        this.companiesStore.setActive(id);
    }

    inviteAdministrators(companyId: ID, emails: string[]): Observable<HttpResponse<object>> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        return this.http.put(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixCompany}/${companyId}/admin/invite`, {
            emails
        }, {observe: 'response'}).pipe(
            tap(() => {
                this.companiesStore.setError(null);
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    deletePendingAdministrator(companyId: ID, email: string): Observable<boolean> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        const params = new HttpParams().set('email', encodeURIComponent(email));

        return this.http.delete(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixCompany}/${companyId}/admin/pending`, {
            params
        }).pipe(
            map(() => {
                this.companiesStore.setError(null);
                return true;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    deleteAdministrator(companyId: ID, userID: ID): Observable<boolean> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        return this.http.delete(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixCompany}/${companyId}/admin/${userID}/unassign`).pipe(
            map(() => {
                this.companiesStore.setError(null);
                return true;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    updateCompany(company: Company) {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        return this.http.patch(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixCompany}/${company.id}`, {
            ...cleanObject(createCompanyToServer(company))
        }).pipe(
            map((response: CompanyFromServer) => {
                const result = createCompany(response);
                result.userEvents = company.userEvents
                this.companiesStore.upsertMany([result]);
                this.companiesStore.setError(null);
                return result;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    changeOwnership(companyId: ID, adminId: ID) {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixCompany}/${companyId}/owner/${adminId}`, []).pipe(
            map((response: CompanyFromServer) => {
                const result = createCompany(response);
                this.companiesStore.upsertMany([result]);
                this.companiesStore.setError(null);
                return result;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    updateUserDefault(defaultCompanyId: number) {
        this.companiesStore.updateUI({
            defaultCompanyId
        });
    }

    createCompany(company: Company) {
        return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixCompany}`, {
            ...cleanObject(createCompanyToServer(company))
        }).pipe(
            map((response: CompanyFromServer) => {
                const result = createCompany(response);
                this.updateUserDefault(result.id);
                this.usermavenService.updateCompany(result);
                this.companiesStore.upsertMany([result]);
                this.companiesStore.setError(null);
                return result;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    deleteCompany(name: string, companyId: ID) {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        return this.http.delete(`${environment.appApi.baseUrl}${this.suffixV1}/company/${companyId}`, {
            params: {
                name
            }
        }).pipe(
            map(() => {
                this.companiesStore.remove(companyId);
                this.eventsStore.remove(event => event.companyId === companyId);
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    cancelSubscription(companyId: ID, reason: string, details: string, anotherTool: string, rating: number): Observable<Company> {
        return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/company/${companyId}/subscription/cancel`, cleanObject({
            reason,
            details,
            another_tool: anotherTool,
            rating
        })).pipe(
            map((company: CompanyFromServer) => {
                this.productFruitsService.updateUserData({hasCanceledSubscription: true})
                const c = createCompany(company);
                this.companiesStore.upsertMany([c]);
                return c;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }

    renewSubscription(companyId: ID): Observable<Company> {
        return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/company/${companyId}/subscription/renew`, {}).pipe(
            map((company: CompanyFromServer) => {
                this.productFruitsService.updateUserData({hasCanceledSubscription: false})
                const c = createCompany(company);
                this.companiesStore.upsertMany([c]);
                return c;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }

    getInvoices(companyId: ID): Observable<InvoicePreview[]> {
        this.companiesStore.setLoading(true);
        this.companiesStore.setError(null);

        return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixCompany}/${companyId}/invoices`).pipe(
            map((response: InvoicePreviewFromServer[]) => {
                const result = response.map(i => createInvoicePreview(i))
                this.companiesStore.setError(null);
                return result;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }),
            finalize(() => this.companiesStore.setLoading(false))
        );
    }

    createOrUpdateRestrictions(companyId: ID, restrictions: CompanyPackageRestrictions): Observable<CompanyPackageRestrictions> {
        return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixCompany}/${companyId}/restrictions`,
            createCompanyPackageRestrictionsToServer(restrictions))
            .pipe(
                map((response: CompanyPackageRestrictionsFromServer) => {
                    const restrictions = createCompanyPackageRestrictions(response);
                    this.companiesStore.updateRestrictions(restrictions);
                    return restrictions;
                }),
                switchMap(restrictions => {
                    return this.get(companyId).pipe(map(() => restrictions));
                }),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    getRestrictions(companyId: ID): Observable<CompanyPackageRestrictions> {
        return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixCompany}/${companyId}/restrictions`).pipe(
            map((response: CompanyPackageRestrictionsFromServer) => {
                const restrictions = createCompanyPackageRestrictions(response);
                this.companiesStore.updateRestrictions(restrictions);
                return restrictions;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }
}
