import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';
import {getTimeZone} from '../../../global';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export function dateTimeFromServerInLocalTimezone(dateTime: string) {
	if (!dateTime) {
		return null;
	}
	return moment.tz(dateTime, 'YYYY-MM-DD HH:mm:ss', 'UTC').local()
}

export function dateTimeFromServerInUTC(dateTime: string) {
	if (!dateTime) return null;
	return moment.tz(dateTime, 'YYYY-MM-DD HH:mm:ss', 'UTC');
}

export function dateTimeFromServer(dateTime: string) {
	if (!dateTime) return null;
	return moment.tz(dateTime, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(getTimeZone());
}

export function dateTimeToServer(date: Moment) {
	return date.clone().utc().format('YYYY-MM-DD HH:mm:ss');
}

export function ceil(time: Moment, ceilBy: DurationConstructor) {
	return time.subtract(1, 'millisecond').add(1, ceilBy).startOf(ceilBy);
}

export function floor(time: Moment, floorBy: DurationConstructor) {
	return time.startOf(floorBy);
}
