import {FeatureFlagsService} from './feature-flags.service';

const featureFlagOverride = (defaultValue: any) => {
	return (target: any, memberName: string) => {
		let currentValue: any = target[memberName];

		Object.defineProperty(target, memberName, {
			set: (newValue: any) => {
				const overrides = FeatureFlagsService.getOverrides();

				currentValue = newValue;

				if (overrides && memberName in overrides) {
					currentValue = overrides[memberName]
				}
			},
			get: () => currentValue || defaultValue
		});
	};
}

export enum FeatureFlagType {
	EVENT_INFO_IN_SETTINGS = 'event-info-in-settings',
	INSTAGRAM = 'instagram',
	HIDE_MY_AGENDA = 'hide-my-agenda',
	BRANDING_PARTNERS_BACKGROUND = 'branding-partners-background',
	DEFAULT_PROGRAM_VIEW_SETTING = 'default-program-view-setting',
	INTEGRATION_WITH_ZOOM = 'integration-with-zoom',
	GAMIFICATION = 'gamification',
	RATING_LEVEL = 'rating-level',
	USERS_V2 = 'users-v2',
}

export class FeatureFlags {

	@featureFlagOverride(false)
	public eventInfoInSettings: boolean;

	@featureFlagOverride(false)
	public instagram: boolean;

	@featureFlagOverride(false)
	public hideMyAgenda: boolean;

	@featureFlagOverride(false)
	public brandingPartnersBackground: boolean;

	@featureFlagOverride(false)
	public defaultProgramViewSetting: boolean;

	@featureFlagOverride(false)
	public integrationWithZoom: boolean;

	@featureFlagOverride(false)
	public gamification: boolean;

	@featureFlagOverride(false)
	public ratingLevel: boolean;

	@featureFlagOverride(false)
	public usersV2: boolean;

	constructor() {
	}
}