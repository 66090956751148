import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';
import {LOCATION_INITIALIZED} from '@angular/common';
import {FeatureFlagsService} from '../../libraries/feature-flags/feature-flags.service';
import {AuthService} from '../../store/auth';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {firstValueFrom} from "rxjs";

export function eventeeInitializerFactory(authService: AuthService, featureFlagsService: FeatureFlagsService, router: Router) {
	return async () => {
		await FeatureFlagsService.growthBookLoader(featureFlagsService);

		if (localStorage.getItem('token') && !location.href.includes('o-auth-redirect')) {
			try {
				const user = await firstValueFrom(authService.me());

				featureFlagsService.setGrowthBookAttributes({
					userEmail: user.email,
					country: user.country
				});
			} catch (e) {
				try {
					await firstValueFrom(authService.logout());
				} catch (e) {
					console.error(e);
				} finally {
					await router.navigate(['auth']);
				}
			}
		}
	}
}
