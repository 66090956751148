import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';
import {environment} from '../../../environments/environment';
import packageInfo from '../../../../package.json';

@Injectable()
export class ErrorsHandler extends BugsnagErrorHandler {

	constructor() {
		Bugsnag.start({
			appVersion: packageInfo.version,
			releaseStage: environment.bugsnag.stage,
			apiKey: environment.bugsnag.key,
			enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
			enabledReleaseStages: ['production', 'staging'],
		});

		super();
	}

	handleError(response: Error | HttpErrorResponse) {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;

		if (chunkFailedMessage.test(response?.message)) {
			window.location.reload();
		} else {
			super.handleError(response);
		}
	}
}
